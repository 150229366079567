import React from 'react'
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import Layout from '../../components/Layout'

class Election2020BondPage extends React.Component {
  render() {
    return (
  <Layout>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1">2020 Bond Election</h1>
              <p>See documents and articles related to the 2020 bond election.</p>
              <hr />
              <div className="news-preview-container">
                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Order Cancelling Bond Election 2020.pdf">Order Cancelling Bond Election (Orden de Cancelación Elección de Bonos)</a>
                    <br /><small className="accent-color">April 15, 2020</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Order Cancelling Bond Election 2020.pdf">View</a>
                  </div>
                </div>
                <div className="news-preview-item">
                  <div className="news-preview-content">
                    <p className="is-size-5">
                    <a href="/assets/Notice of Appointed Agent (2020 Bond Election).pdf">Notice of Appointed Agent (Aviso De Agente Nominado)</a>
                    <br /><small className="accent-color">March 13, 2020</small></p>
                  </div>
                  <div className="news-preview-footer">
                    <a className="button is-white" href="/assets/Notice of Appointed Agent (2020 Bond Election).pdf">View</a>
                  </div>
                </div>

              </div>
              <div className="section">
              <hr />
              <p><strong>English</strong><br />
              If you need this election document provided in Spanish, please send us a message requesting the translated document using the <Link to="/contact">“Contact Us”</Link> link on this website.</p>

              <p><strong>Spanish</strong><br />
              Si necesita que le entreguen este documento sobre la elección en español, envíenos un mensaje solicitando el documento traducido a través del enlace <Link to="/contact">“Contact Us”</Link> de este sitio web.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
}
}

export default Election2020BondPage
